import React, { PureComponent } from 'react';
import { node } from 'prop-types';

import CategoriesContext from 'Contexts/CategoriesContext';

export default class CategoriesProvider extends PureComponent {
    static propTypes = {
        children: node,
    };

    /* eslint-disable react/no-unused-state */
    constructor(props) {
        super(props);

        this.state = {
            categories: [],
            updateState: this.updateState,
        };
    }
    /* eslint-enable react/no-unused-state */

    get value() {
        return this.state;
    }

    updateState = state => this.setState(state);

    render() {
        return (
            <CategoriesContext.Provider value={ this.value }>
                { this.props.children }
            </CategoriesContext.Provider>
        );
    }
}
