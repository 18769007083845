import React, { PureComponent } from 'react';
import { node } from 'prop-types';
import noop from 'lodash-es/noop';

import ProductsContext from 'Contexts/ProductsContext';
import fetchHelper from 'Helpers/fetch';
import { parseQueryParams } from 'Helpers';
import ProductModel from 'Models/ProductModel';

export default class ProductsProvider extends PureComponent {
    static propTypes = {
        children: node,
    };

    // Disabling no-unused-state prop since this component does not use it
    /* eslint-disable react/no-unused-state */
    constructor(props) {
        super(props);

        const defaultState = {
            latestSearch: null,
            scrollTop: 0,
            products: [],
            promos: [],
            error: null,
            loading: false,
            hasMoreItems: true,
            page: 1,
        };

        this.state = {
            ...defaultState,
            clearState: (cb = noop) => {
                this.setState({ ...defaultState }, cb);
            },
            updateState: (state, cb = noop) => {
                this.setState(state, cb);
            },
            getItemsLoader: this.getItemsLoader,
        };
    }

    /**
     * Fetch new items and save to state as type
     * @param { string } url
     * @param { string } query
     * @param { object } options
     * @param { string } options.page
     * @param { string } options.type
     * @returns {Promise<T>}
     */
    getItemsLoader = (url, query, options) => {
        const { hasMoreItems } = this.state;
        const { page, type } = options;

        this.setState({ loading: true });

        if (!hasMoreItems) return Promise.resolve(null);

        const {
            order = type === 'products' ? 'price' : 'discount',
            order_by = type === 'products' ? 'asc' : 'desc', // eslint-disable-line
            per_page = 10,  // eslint-disable-line
        } = parseQueryParams(query).filter(i => i.key.match(/order|page/));

        const params = {
            page,
            per_page,
        };

        if (!query.match(/order|order_by/ig)) {
            Object.assign(params, {
                order,
                order_by,
            });
        }

        return fetchHelper(`${url}${query}`, { params })
            .then(response => {
                if (response) {
                    const { data, data: { items = [] } } = response;
                    const newProducts = items
                        .map(prod => new ProductModel(prod));

                    if (!data.next_page) {
                        this.setState(prevState => ({
                            ...prevState,
                            loading: false,
                            hasMoreItems: false,
                            [type]: prevState[type].concat(newProducts),
                        }));
                        return;
                    }

                    this.setState(prevState => ({
                        ...prevState,
                        loading: false,
                        [type]: prevState[type].concat(newProducts),
                    }));
                }
            })
            .catch(err => {
                this.setState({
                    error: err,
                    loading: false,
                });
                throw err;
            });
    };

    /* eslint-enable react/no-unused-state */

    get value() {
        return this.state;
    }

    render() {
        return (
            <ProductsContext.Provider value={ this.value }>
                { this.props.children }
            </ProductsContext.Provider>
        );
    }
}
