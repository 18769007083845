import './Modals.scss';

import React, { useEffect } from 'react';
import { func, bool, node, string } from 'prop-types';
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
import clsx from 'clsx';

const Modal = props => {
    const {
        open,
        header,
        footer,
        className,
        children,
        setOpen,
        getContainer,
        scrollable = true,
        size = 'xl',
    } = props;

    useEffect(() => {
        if (open) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'auto';
        }
    }, [open]);

    return (
        <BootstrapModal
            onExiting={ () => {
                setOpen(false);
            } }
            container={ getContainer() }
            show={ open }
            centered
            onHide={ () => setOpen(false) }
            dialogClassName={ clsx('container modal', className) }
            scrollable={ scrollable }
            size={ size }
        >
            <BootstrapModal.Header>
                { header || (
                    <Button
                        variant="secondary"
                        onClick={ () => setOpen(false) }
                    >
                        Inchide
                        { ' ' }
                        <span>x</span>
                    </Button>
                ) }
            </BootstrapModal.Header>
            <BootstrapModal.Body>
                { children }
            </BootstrapModal.Body>
            <BootstrapModal.Footer>
                { footer || (
                    <Button
                        variant="secondary"
                        onClick={ () => setOpen(false) }
                    >
                        Inchide
                    </Button>
                ) }
            </BootstrapModal.Footer>
        </BootstrapModal>
    );
};

Modal.propTypes = {
    open: bool,
    setOpen: func,
    getContainer: func,
    children: node,
    header: node,
    footer: node,
    className: string,
    scrollable: bool,
    size: string,
};

Modal.defaultProps = {
    getContainer: () => {},
};

export default Modal;
