import './Modals.scss';
import './WizardModal.scss';

import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { ReactComponent as IconQuestion } from 'AppSrc/assets/images/question.svg';
import StaticContentModal from 'Components/Modals/StaticContentModal';

import { DASHBOARD_PAGE_URL } from 'Constants';

const WizardModal = () => {
    const containerRef = useRef(null);
    const [open, setOpen] = useState(false);
    const history = useHistory();

    return (
        // This PAGE should always have the wizard slug, otherwise it won't work !
        <div className="Wizard-component" ref={ containerRef }>
            <StaticContentModal
                open={ open }
                setOpen={ setOpen }
                slug="wizard"
                containerRef={ containerRef }
            >
                <div className="faq-extra-content">
                    <Button
                        variant="link"
                        onClick={ () => {
                            setOpen(false);
                            history.push(DASHBOARD_PAGE_URL);
                        } }
                    >
                        Vezi comenzile
                    </Button>
                </div>
            </StaticContentModal>
            <div className="footer">
                <Button
                    onClick={ () => {
                        setOpen(true);
                    } }
                >
                    <IconQuestion />
                    <p>Cum functioneaza?</p>
                </Button>
            </div>
        </div>
    );
};

export default WizardModal;
