import './Search.scss';

import React, { useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import { object } from 'prop-types';
import { withRouter } from 'react-router-dom';

import { PRODUCTS_PAGE_URL } from 'Constants';
import { parseQueryParams } from 'Helpers';
import iconSearch from 'Assets/images/search.svg';

const Search = props => {
    const { history: { push, replace, location } } = props;
    const searchQp = Object.assign(
        { value: null },
        parseQueryParams(location.search).find(v => v.key === 'search'),
    );
    const originalSearch = searchQp.value;
    const [state, setState] = useState({
        search: originalSearch || '',
        open: originalSearch,
    });

    useEffect(() => {
        if (state.search.length && !location.search.length) {
            setState({
                search: '',
                open: false
            });
        }
    }, [location.search]);

    const inputRef = useRef(null);
    const formRef = useRef(null);
    const formSubmit = useRef(null);

    const handleOnSubmit = e => {
        e.preventDefault();
        if (state.search.length) {
            if (location.pathname === PRODUCTS_PAGE_URL) {
                replace({
                    search: `search=${ state.search }`,
                });
            } else {
                push({
                    pathname: PRODUCTS_PAGE_URL.replace(/\/$/, ''),
                    search: `search=${ state.search }`,
                });
            }
        }
    };

    const handleOnClick = e => {
        const newState = { search: state.search };
        e.preventDefault();
        if (!state.open) {
            setState({
                search: state.search,
                open: true,
            });
        }
        if (!state.search.length) {
            newState.open = !state.open;
            setState(newState);
        }
        if (
            Reflect.has(newState, 'open')
                ? newState.open
                : state.open
                && inputRef.current instanceof Element
        ) {
            setTimeout(() => inputRef?.current?.focus(), 100);
        }
        if (state.open && state.search.length) {
            formSubmit.current.click();
        }
    };

    return (
        <div className={ clsx('Search', { open: state.open }) }>
            <form
                ref={ formRef }
                onSubmit={ handleOnSubmit }
                autoComplete="off"
            >
                <button
                    type="button"
                    aria-label="Inchide"
                    className="close"
                    onClick={ () => {
                        setState({
                            open: false,
                            search: state.search,
                        });
                    } }
                />
                <input
                    ref={ inputRef }
                    type="search"
                    autoComplete="off"
                    name="q"
                    className="search-control"
                    placeholder="Caută ..."
                    value={ state.search }
                    onChange={ e => setState({
                        search: e.target.value,
                        open: state.open,
                    }) }
                />
                <button
                    type="submit"
                    className="hidden"
                    style={{ border: 0, padding: 0, outline: 0 }}
                    ref={ formSubmit }
                />
            </form>
            <button
                type="button"
                className="submit"
                onClick={ handleOnClick }
            >
                <object data={ iconSearch } type="image/svg+xml">
                    <img src={ iconSearch } alt="Cauta" />
                </object>
                <span className="text">Cauta</span>
            </button>
        </div>
    );
};

Search.propTypes = {
    history: object,
};

export default withRouter(Search);
