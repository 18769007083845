import DOMPurify from 'dompurify';
import memoize from 'micro-memoize';
import isEqual from 'react-fast-compare';

export const parseQueryParams = memoize(queryString => {
    const queryParamPattern = /([a-zA-Z_-]+)=([^&]+)/g;
    const matches = queryString.match(queryParamPattern) || [];

    return matches.reduce((acc, queryPairString) => {
        const [key, value] = queryPairString.split('=');
        acc.push({
            key: decodeURIComponent(key),
            value: decodeURIComponent(value),
        });
        return acc;
    }, []);
}, { maxSize: 10 });

export const encodeQueryParams = memoize(qp => {
    const queryParams = Array.isArray(qp)
        ? qp
        : Object.entries(qp).map(([key, value]) => ({ key, value }));

    return queryParams
        .reduce((acc, param, idx) => {
            /* eslint-disable no-param-reassign */
            if (idx === 0) {
                acc += '?';
            }
            const key = param.key ? param.key : Object.keys(param)[0];
            const value = param.value ? param.value : Object.values(param);
            if (Array.isArray(value)) {
                if (idx) {
                    acc += '&';
                }
                acc += `${ encodeURIComponent(key) }=`;
                value.forEach((v, i) => {
                    if (i) {
                        acc += ',';
                    }
                    // eslint-disable-next-line no-param-reassign
                    acc += encodeURIComponent(v);
                });
            } else {
                if (idx) {
                    acc += '&';
                }
                acc += `${ encodeURIComponent(key) }=${ encodeURIComponent(value) }`;
            }
            /* eslint-enable no-param-reassign */
            return acc;
        }, '');
}, { maxSize: 5, isEqual });

export const htmlText = text => ({ __html: DOMPurify.sanitize(text) });
