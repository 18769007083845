import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import { CART_PAGE_URL } from 'Constants';
import CartContext from 'Contexts/CartContext';
import { ReactComponent as IconCart } from 'Assets/images/cart.svg';

const CartThumbnail = () => {
    const cartCtx = useContext(CartContext);
    const [qty, setQty] = useState(0);
    useEffect(() => {
        setQty(cartCtx.size());
    }, [cartCtx.products]);
    return (
        <Link to={ CART_PAGE_URL } className="header-link cart">
            { qty ? (
                <span className="items">{ qty }</span>
            ) : null }
            <IconCart />
            Cosul meu
        </Link>
    );
};

export default CartThumbnail;
