import './ErrorBoundary.scss';

import React, { PureComponent } from 'react';
import { object, node } from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { HOME_PAGE_URL } from 'Constants';
import Header from 'Components/Header/Header';
import UserContext from 'Contexts/UserContext';

export default
@withRouter
class ErrorBoundary extends PureComponent {
    static propTypes = {
        children: node,
        location: object,
    };

    state = {
        hasError: false,
        // eslint-disable-next-line react/no-unused-state
        error: null,
    };

    static getDerivedStateFromProps(props, state) {
        if (
            state.hasError
            && state.location
            && props.location.pathname !== state.location
        ) {
            return {
                hasError: false,
                error: null,
            };
        }
        return null;
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    static contextType = UserContext;

    componentDidCatch() {
        const { currentUser } = this.context;
        const { location } = this.props;
        const { hasError, error } = this.state;
        this.setState({ location: location.pathname });
        if (hasError) {
            fetch('/api/log', {
                method: 'PUT',
                headers: {
                    Authorization: `Basic ${ currentUser.user_token }`,
                },
                body: JSON.stringify({
                    payload: {
                        time: Date.now(),
                        user: currentUser.user_email,
                        location: location.pathname,
                        userAgent: navigator.userAgent,
                        message: error?.message || error,
                        stack: error.stack.slice(0, 200)
                    },
                }),
            });
        }
    }

    render() {
        const { children } = this.props;
        const { hasError } = this.state;
        if (hasError) {
            return (
                <div className="Error">
                    <Header />
                    <main className="container">
                        <h1 className="title">NE PARE RAU!</h1>
                        <section className="description">
                            <p>
                                A aparut o eroare, insa un mesaj a fost trimis
                                automat catre echipa de suport.
                            </p>
                        </section>
                        <Link to={ HOME_PAGE_URL }>
                            <Button
                                variant="secondary"
                                className="offer-details-button"
                            >
                                Inapoi la oferte
                            </Button>
                        </Link>
                    </main>
                </div>
            );
        }

        return children;
    }
}
