import { PureComponent } from 'react';
import { object, string, func } from 'prop-types';
import isEqual from 'react-fast-compare';

import fetchHelper, { getFetchCtrl } from 'Helpers/fetch';

export default class Fetch extends PureComponent {
    static propTypes = {
        url: string.isRequired,
        urlParams: object,
        options: object,
        children: func.isRequired,
    };

    state = {
        loading: true,
        error: null,
        data: null,
        fetchData: this.fetchData.bind(this),
    };

    componentDidMount() {
        const { urlParams = {}, options = {}, url } = this.props;
        if (!url) {
            throw new Error('Fetch Type and url not defined !');
        }

        this.fetchData(url, { params: urlParams, ...options });
    }

    componentDidUpdate(prevProps) {
        const {
            urlParams = {},
            options = {},
            url,
        } = prevProps;
        const {
            urlParams: nextParams = {},
            options: nextOptions = {},
            url: nextUrl,
        } = this.props;

        if (
            (nextUrl && url !== nextUrl)
            || (nextParams && !isEqual(urlParams, nextParams))
            || (nextOptions && !isEqual(options, nextOptions))
        ) {
            this.fetchData(nextUrl, { params: nextParams, ...nextOptions });
        }
    }

    componentWillUnmount() {
        const { url } = this.props;
        const fetchCtrl = getFetchCtrl(url);
        if (fetchCtrl) {
            fetchCtrl.abort();
        }
    }

    async fetchData(url, options) {
        this.setState({ loading: true, data: null, error: null });
        try {
            const res = await fetchHelper(url, options);
            if (res) {
                const { data } = res;
                const payload = { data, loading: false, error: null };
                this.setState({ loading: false, ...payload });
            }
        } catch (err) {
            this.setState({ error: err, loading: false });
        }
    }

    render() {
        return this.props.children(this.state);
    }
}
