import { placeHolderImage } from 'Constants';

const perkTypes = {
    warranty: v => `Garantie ${ v } luni`,
    refundLimit: v => `${ v } zile drept de retur`,
    shippingTime: v => `Ajunge in ${ v } zile`,
};

/**
 * @typedef ProductModel {{
 *     id: string,
 *     uid: string,
 *     name: string,
 *     featuredImage: {{
 *         src: string,
 *         alt: string,
 *     }},
 *     price: {{
 *         regular: number,
 *         actual: number,
 *     }},
 *     gallery: array,
 *     attributes: array,
 *     description: string,
 *     perks: array,
 *     stockQty: number,
 *     promotions: array,
 * }}
 */
/* eslint-disable camelcase */
export default class ProductModel {
    id = '';

    uid = '';

    nodId = '';

    sku = '';

    name = '';

    description = '';

    price = {
        regular: 0.0,
        actual: 0.0,
        total: 0.0,
    };

    perks = [];

    gallery = [];

    featuredImage = {
        src: '',
        alt: '',
    };

    promotions = [];

    attributes = [];

    quantity = 1;

    stockQty = 0;

    minQty = 1;

    featuredPromotion = null;

    constructor(product) {
        /* eslint-disable */
        if (product.perks) {
            if (!isNaN(parseInt(product.id, 10))) {
                product.id = parseInt(product.id, 10);
            }
            if (!isNaN(parseInt(product.uid, 10))) {
                product.uid = parseInt(product.uid, 10);
            }
            Object.assign(this, product);
            return this;
        }

        if (!isNaN(parseInt(product.id, 10))) {
            this.id = parseInt(product.id, 10);
        } else {
            this.id = product.id;
        }
        this.uid = product.id;
        this.nodId = product.nodId;
        this.sku = product.sku;
        /* eslint-enable */
        this.name = product.name;
        this.price.regular = parseFloat(product.price.regular);
        this.price.actual = this.price.regular;
        this.description = product.description;
        this.status = product.status;
        this.stockQty = product.stockQty;
        this.featuredImage = {
            original: { src: placeHolderImage.src, alt: product.name },
            thumbnail: { src: placeHolderImage.src, alt: product.name },
            ...product.featuredImage,
        };
        this.gallery = product.gallery;
        this.minQty = 'min_quantity' in product
            ? Math.max(1, product.min_quantity)
            : (product.quantity || this.minQty);
        this.perks = this.processPerks(product.usp);
        this.attributes = product.attributes;
        this.promotions = product.promotions.map(promo => {
            Object.assign(promo, {
                featuredImage: {

                    ...this.featuredImage,
                    ...promo.featuredImage,
                },
                products: promo.products.map(promoProduct => ({
                    ...promoProduct,
                    title: promoProduct.name,
                    featuredImage: {
                        original: { src: placeHolderImage.src, alt: product.name },
                        thumbnail: { src: placeHolderImage.src, alt: product.name },
                        ...promoProduct.featuredImage,
                    }
                })),
            });
            if ('discountPercent' in promo) {
                Object.assign(promo, {
                    discountPercent: parseFloat(promo.discountPercent.toFixed(2)),
                });
            }

            return promo;
        });

        if (product.availablePromotions) {
            this.availablePromotions = product.availablePromotions;
        }

        if (this.promotions.length) {
            [this.featuredPromotion] = this.promotions;
        }
    }

    processPerks(perks = {}) {
        return Object
            .entries(perks)
            .reduce((acc, [name, value]) => {
                if (value !== '' || value !== null) {
                    acc.push({
                        key: name,
                        value: perkTypes[name](value),
                    });
                }
                return acc;
            }, []);
    }
}
/* eslint-enable camelcase */
