import './StaticContentModal.scss';

import React, { useRef, useState } from 'react';
import { func, bool, string, node } from 'prop-types';
import { htmlText } from 'Helpers';
import Fetch from 'Containers/Fetch/Fetch';
import { API_PAGES_URL } from 'Constants';
import Loader from 'Components/Loader/Loader';
import ErrorMessage from 'Components/ErrorMessage/ErrorMessage';
import Modal from './Modal';

const StaticContentModal = ({ open, setOpen, slug, children }) => {
    const modalRef = useRef(null);
    const [pageData, setPageData] = useState({
        [slug]: {
            title: null,
            content: null,
        },
    });
    return open ? (
        <div className="StaticContentModal-component" ref={ modalRef }>
            <Fetch
                url={ API_PAGES_URL }
                urlParams={{ slug, from: 'cactus' }}
                options={{ open }}
            >
                { ({ loading, error, data }) => {
                    if (data) {
                        try {
                            if (data && data[0]) {
                                const title = data[0].title?.rendered;
                                const content = data[0].content?.rendered;
                                if (title !== pageData[slug].title) {
                                    setPageData({
                                        ...pageData,
                                        [slug]: {
                                            ...pageData[slug],
                                            title,
                                        },
                                    });
                                }
                                if (content !== pageData[slug].content) {
                                    setPageData({
                                        ...pageData,
                                        [slug]: {
                                            ...pageData[slug],
                                            content,
                                        },
                                    });
                                }
                            }
                        } catch (e) {
                            if (!error) {
                                error = e; // eslint-disable-line
                            }
                        }
                    }
                    return (
                        <Modal
                            open={ open }
                            getContainer={ () => modalRef }
                            setOpen={ setOpen }
                            header={ pageData[slug].title && (
                                <div
                                    dangerouslySetInnerHTML={ htmlText(pageData[slug].title) }
                                />
                            ) }
                        >
                            { pageData[slug].content && (
                                <div
                                    dangerouslySetInnerHTML={
                                        htmlText(pageData[slug].content)
                                    }
                                />
                            ) }
                            { (loading && !pageData[slug].content) && <Loader /> }
                            { error && (
                                <ErrorMessage
                                    message="A aparut o eroare, te rugam sa incerci mai tarziu."
                                />
                            ) }
                            { !error && children }
                        </Modal>
                    );
                } }
            </Fetch>
        </div>
    ) : null;
};

StaticContentModal.propTypes = {
    open: bool,
    slug: string,
    children: node,
    setOpen: func,
};

export default StaticContentModal;
