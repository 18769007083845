import 'regenerator-runtime/runtime';

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { render as reactDomRender } from 'react-dom';
import UserProvider from 'Providers/UserProvider';
import ScrollToTop from 'Components/ScrollToTop/ScrollToTop';
import App from './App';
// import * as serviceWorker from './serviceWorker';

if (process.env.GTM_ID) {
    const TagManager = require('react-gtm-module'); // eslint-disable-line
    const tagManagerArgs = {
        gtmId: process.env.GTM_ID,
    };

    if (process.env.GTM_PREVIEW && process.env.GTM_AUTH) {
        Object.assign(tagManagerArgs, {
            auth: process.env.GTM_AUTH,
            preview: process.env.GTM_PREVIEW
        });
    }

    TagManager.initialize(tagManagerArgs);
} else {
    console.warn('GTM ID not provided, can`t load Tag Manager without it');
}

const render = AppContainer => reactDomRender(
    <Router>
        <ScrollToTop>
            <UserProvider>
                <AppContainer />
            </UserProvider>
        </ScrollToTop>
    </Router>,
    document.getElementById('root'),
);

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

if (module.hot) {
    module.hot.accept('./App.js', () => {
        render(require('./App.js')); // eslint-disable-line
    });
}
