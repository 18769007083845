import './Loader.scss';

import React from 'react';
import clsx from 'clsx';
import { node, string } from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';

const Loader = ({ animation, size, variant, className, children }) => (
    <div className={ clsx('Loader', className) } role="status">
        <Spinner
            size={ size }
            animation={ animation }
            variant={ variant }
            className="spinner"
        >
            <span className="sr-only">Se incarca...</span>
        </Spinner>
        { children }
    </div>
);

Loader.propTypes = {
    className: string,
    animation: string,
    variant: string,
    size: string,
    children: node
};

Loader.defaultProps = {
    className: '',
    variant: 'primary',
    animation: 'grow',
    size: 'xl'
};

export default Loader;
