import React, { PureComponent } from 'react';
import { node } from 'prop-types';
import isEqual from 'react-fast-compare';

import CartContext from 'Contexts/CartContext';
import UserContext from 'Contexts/UserContext';
import ProductModel from 'Models/ProductModel';

export default class CartProvider extends PureComponent {
    static contextType = UserContext;

    static propTypes = {
        children: node,
    };

    state = {
        products: [],
        orderId: null,
        // shippingPrice: 0,
        shippingPrice: 20,
        // shippingSteps: [],method_title
        shippingSteps: [{
            method_id: 'flat_rate',
            title: 'Flat rate',
            settings: {
                cost: {
                    value: 20,
                },
            },
        }],
    };

    constructor(props, context) {
        super(props);

        const { currentUser, updateUser, currentUser: { cart = {} } } = context;
        let products = [];
        if (currentUser && cart?.products) {
            products = products.concat(cart.products || []);
        }
        this._updateCart = this._updateCart.bind(this);
        Object.assign(this.state, {
            products: products.map(product => new ProductModel(product)),
            // shippingPrice: cart?.shippingPrice || 0,
            size: () => this._calculateTotalQuantity(),
            discount: () => parseFloat(this._calculateTotalDiscount().toFixed(2)),
            total: () => parseFloat(this._calculateTotalPrice().toFixed(2)),
            updateCartState: newState => {
                this.setState(newState, this._updateCart);
            },
            emptyCart: () => {
                this.setState({
                    products: [],
                    // shippingPrice: 0,
                    shippingPrice: 20,
                }, () => {
                    updateUser({
                        orderWizard: {},
                        cart: {},
                    });
                });
            },
            addProductToCart: product => {
                let newProduct = product;
                if (!(product instanceof ProductModel)) {
                    newProduct = new ProductModel(newProduct);
                }
                this._addProduct(newProduct, this._updateCart);
            },
            removeProductFromCart: product => {
                let newProduct = product;
                if (!(product instanceof ProductModel)) {
                    newProduct = new ProductModel(newProduct);
                }
                this._removeProduct(newProduct, this._updateCart);
            },
            requestMaxQty: product => {
                let newProduct = product;
                if (!(product instanceof ProductModel)) {
                    newProduct = new ProductModel(newProduct);
                }
                const items = this._getMaxAvailable(newProduct);
                if (items.length) {
                    return items[0].maxAvailQty;
                }
                return newProduct.stockQty;
            },
            updateQuantity: (product, newQty) => {
                /* eslint-disable */
                if (!(product instanceof ProductModel)) {
                    // this better be a ProductModel instance !
                    product = new ProductModel(product);
                }
                if (!this._canBuyMore(product, newQty)) return;
                const existingProduct = this._findExistingProduct(product);
                if (!existingProduct) {
                    console.error(`You are trying to update qty for a product that is not in the cart`);
                    return;
                }
                this.setState(prevState => {
                    const idx = prevState.products.indexOf(existingProduct);
                    const newProduct = Object.assign(
                        Object.create(Object.getPrototypeOf(existingProduct)),
                        {
                            ...existingProduct,
                            quantity: newQty,
                        }
                    );
                    if (!(newProduct instanceof ProductModel)) {
                        debugger;
                    }
                    return {
                        ...prevState,
                        products: prevState.products.reduce((productsAcc, product, productIdx) => {
                            if (idx === productIdx) {
                                productsAcc.push(newProduct);
                            } else {
                                productsAcc.push(product);
                            }
                            return productsAcc;
                        }, [])
                    };
                });
                this._updateCart();
                /* eslint-enable */
            },
        });
    }

    get value() {
        return this.state;
    }

    /**
     * returns a flat map of product models
     * @param {ProductModel} product
     * @returns { object }
     * @private
     */
    _getFlatCart = product => this.state.products.reduce((cart, prod) => {
        if (product && isEqual(product, prod)) return cart;
        if (prod.featuredPromotion) {
            const promos = (
                prod
                    .featuredPromotion
                    .products
                || []
            ).reduce((promoProducts, promoProduct) => {
                promoProducts[promoProduct.id] = (promoProducts[promoProduct.id] || []) //eslint-disable-line
                    .concat({
                        quantity: prod.quantity,
                        ...promoProduct,
                    });
                return promoProducts;
            }, {});

            Object.assign(cart, promos);
            return cart;
        }
        cart[prod.id] = (cart[prod.id] || []).concat(prod); //eslint-disable-line
        return cart;
    }, {});

    /**
     * Gets maximum available items for a product
     * @param product
     * @returns {number}
     * @private
     */
    _getMaxAvailable = product => {
        // Get a flat list of all products belonging to this item
        const allProducts = product.featuredPromotion
            ? product.featuredPromotion.products.map(prod => ({
                quantity: product.quantity,
                ...prod,
            }))
            : [product];
        // Get a flat list of all other items currently in the cart
        const flatCart = this._getFlatCart(product);
        // Compute a list of existing cart quantities for the other items
        const existingQuantities = Object
            .entries(flatCart)
            .filter(([id]) => allProducts.find(prod => prod.id == id)) // eslint-disable-line
            .reduce((prodQtyMap, [id, products]) => Object.assign(
                prodQtyMap, {
                    [id]: products
                        .reduce((total, prod) => total + (prod.quantity * (prod.minQty || 1)), 0),
                }), {});

        // Sort the list by minimum computed available stock
        return allProducts
            .map(prod => ({
                maxAvailQty: Math.max(0, Math.floor((prod.stockQty - (existingQuantities[prod.id] || 0)) / prod.minQty)),
                ...prod,
            }))
            .sort((a, b) => a.maxAvailQty - b.maxAvailQty);
    };

    /**
     * Check if user can buy more newQty number of product
     * @param {ProductModel} product
     * @param {int} [newQty]
     * @returns {boolean}
     * @private
     */
    _canBuyMore = (product, newQty) => {
        /*eslint-disable*/
        const { featuredPromotion, stockQty, minQty } = product;
        let baseNewQty = newQty * minQty;

        if (baseNewQty > stockQty) {
            // Can't buy more than stock.
            throw new Error(`
                Pentru produsul acesta mai avem doar <strong>${ stockQty }</strong> bucati in stoc.
            `);
        }

        if (featuredPromotion) {
            featuredPromotion.products.forEach(promoProd => {
                if (promoProd.minQty * baseNewQty > promoProd.stockQty) {
                    if (promoProd.minQty > 1) {
                        throw new Error(`
                            Pentru produsul <strong>${ promoProd.name }</strong>
                            doriti <strong>${ promoProd.minQty * baseNewQty }</strong>
                            (<strong>${ baseNewQty }</strong> x ${ promoProd.minQty })
                            bucati, insa mai avem doar <strong>${ promoProd.stockQty }</strong>
                            in stoc.
                        `);
                    } else {
                        throw new Error(`
                            Pentru produsul <strong>${ promoProd.name }</strong>
                            doriti <strong>${ promoProd.minQty * baseNewQty }</strong>
                            bucati, insa mai avem doar <strong>${ promoProd.stockQty }</strong>
                            in stoc.
                        `);
                    }
                }
                if (promoProd.id == product.id) {
                    baseNewQty = newQty * promoProd.minQty;
                }
            });
        }

        const items = this._getMaxAvailable(product);
        if (newQty > items[0].maxAvailQty) {
            throw new Error(`
                Pentru produsul ${ items[0].name } mai avem doar
                <strong>${ items[0].maxAvailQty }</strong> bucati in stoc.
            `);
        }

        return true;
        /*eslint-enable*/
    };

    /**
     * Finds an existing product based on uid and featuredPromotion
     * @param product
     * @returns {ProductModel | null}
     * @private
     */
    _findExistingProduct(product) {
        /*eslint-disable*/
        return this
            .state
            .products
            .find(prod => {
                if (
                    product.featuredPromotion
                    && prod.featuredPromotion
                ) {
                    const { id: promoId } = product.featuredPromotion;
                    if (promoId == prod.featuredPromotion.id) {
                        return true;
                    }
                }

                if (product.uid == prod.uid) {
                    if (
                        (!product.featuredPromotion && prod.featuredPromotion)
                        || (product.featuredPromotion && !prod.featuredPromotion)
                    ) {
                        return false;
                    }

                    if (product.featuredPromotion && prod.featuredPromotion) {
                        return (
                            product.featuredPromotion.id
                            == prod.featuredPromotion.id
                        );
                    }

                    return true;
                }
                return false;
            });
        /*eslint-enable*/
    }

    _updateCart() {
        const { products, orderId, shippingPrice, shippingSteps } = this.state;
        this.context.updateUser({
            cart: {
                orderId,
                products,
                shippingPrice,
                shippingSteps,
            },
        });
    }

    /**
     * Remove product from cart
     * @param {ProductModel} product
     * @param {function} cb
     * @private
     */
    _removeProduct(product, cb) {
        this.setState(prevState => ({
            ...prevState,
            products: prevState
                .products
                .filter(prod => !isEqual(prod, product)),
        }), cb);
    }

    /**
     * Adds product to cart
     * @param {ProductModel} product
     * @param {function} cb
     * @private
     */
    _addProduct(product, cb) {
        /** todo: Consider if the promotion is different as well. */
        const existingProduct = this._findExistingProduct(product);

        if (existingProduct) {
            this
                .state
                .updateQuantity(
                    existingProduct,
                    existingProduct.quantity + 1,
                );
        } else {
            this.setState(prevState => ({
                ...prevState,
                products: prevState.products.concat(product),
            }), () => {
                cb();
                this
                    .state
                    .updateQuantity(product, 1);
            });
        }
    }

    _calculateTotalQuantity() {
        /* eslint-disable */
        return this.state.products.reduce((acc, prod) => {
            if (prod.featuredPromotion) {
                // this is a promo, it might have a different minQty
                // so we need to use this instead
                return acc + prod
                    .featuredPromotion
                    .products
                    .reduce((promoAcc, promoProd) =>
                            (promoAcc + promoProd.minQty * prod.quantity)
                        , 0);
            }
            return acc + prod.quantity * prod.minQty;
        }, 0);
        /* eslint-enable */
    }

    _calculateTotalPrice() {
        return (
            this
                .state
                .products
                .reduce((acc, product) => acc + (product.price.actual * product.quantity), 0)
        );
    }

    _calculateTotalDiscount() {
        return Math.abs(
            this
                .state
                .products
                .reduce((acc, product) => acc + (product.price.regular * product.quantity), 0)
            - this._calculateTotalPrice(),
        );
    }

    render() {
        return (
            <CartContext.Provider value={ this.value }>
                { this.props.children }
            </CartContext.Provider>
        );
    }
}
