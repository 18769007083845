import React, { useState, useRef, useEffect, useContext } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import UserContext from 'Contexts/UserContext';

const generateRandomHash = () => `user_${ Math
    .random()
    .toString(36)
    .replace(/[^a-z]+/g, '').substr(0, 5) }`;

const createEvent = hash => new CustomEvent('updateUserSession', {
    detail: {
        state: hash,
    },
});

const getComponentName = Component => Component.displayName || Component.name || 'Component';

const useInterval = (cb, delay) => {
    const savedCb = useRef();

    useEffect(() => {
        savedCb.current = cb;
    }, [cb]);

    useEffect(() => {
        const tick = () => savedCb.current();
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
    }, [delay]);
};

const withSession = Component => {
    const componentName = getComponentName(Component);

    const defaultCounterTimer = 240;

    const WithSession = () => {
        const firstRandomHash = generateRandomHash();
        window.dispatchEvent(createEvent(firstRandomHash));
        const [session, setSession] = useState(firstRandomHash);
        const [counter, setCounter] = useState(defaultCounterTimer);
        const { currentUser = {} } = useContext(UserContext);

        const intervalCb = () => {
            if (counter === 0) {
                const randomHash = generateRandomHash();
                setSession(randomHash);
                window.dispatchEvent(createEvent(randomHash));
                setCounter(defaultCounterTimer);
            } else {
                setCounter(counter - 1);
            }
        };

        const intervalReset = () => {
            setCounter(defaultCounterTimer);
        };

        // Use our custom hook to save the cb's context
        useInterval(intervalCb, 1000);

        // for each session change, update the session cookie and reset the listener
        useEffect(() => {
            if (currentUser.loggedIn) {
                document.cookie = `user_session=${ currentUser.user_id }_${ session }`;
            } else {
                document.cookie = 'user_session=null';
            }
            window.addEventListener('touchstart', intervalReset);
            return () => {
                window.removeEventListener('touchstart', intervalReset);
            };
        }, [session, currentUser.loggedIn]);

        return <Component />;
    };

    WithSession.displayName = `WithSession${ componentName }`;

    return hoistNonReactStatics(
        WithSession,
        Component,
    );
};

export default withSession;
