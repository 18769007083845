import './Header.scss';

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { object, string } from 'prop-types';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

import Search from 'Containers/Search/Search';
import CartThumbnail from 'Components/Cart/CartThumbnail';
import { ReactComponent as Logo } from 'Assets/images/logo.svg';
import { ReactComponent as IconProducts } from 'Assets/images/products.svg';
import { ReactComponent as IconPromo } from 'Assets/images/promo.svg';
import { HOME_PAGE_URL, PROMOS_PAGE_URL, CATEGORIES_PAGE_URL } from 'Constants';

const Header = props => {
    const {
        location: { pathname },
        history: { goBack },
        className,
    } = props;
    return (
        <header
            className={
                clsx('Header', 'nav-header', className, {
                    'back--visible': pathname !== HOME_PAGE_URL,
                })
            }
        >
            <div>
                { pathname !== HOME_PAGE_URL && (
                    <button
                        type="button"
                        className="go-back"
                        id="back-link"
                        onClick={ goBack }
                    >
                        <FontAwesomeIcon
                            color="white"
                            icon={ faAngleLeft }
                        />
                        Inapoi
                    </button>
                ) }
                <Link
                    to={ HOME_PAGE_URL }
                    id="home-link"
                    className="header-link"
                >
                    <Logo />
                </Link>
            </div>
            <div className="nav-right-side">
                <Search />
                <Link
                    id="promos-link"
                    to={{
                        pathname: PROMOS_PAGE_URL,
                        search: '?promotions=true&order=discount&order_by=desc',
                    }}
                    className="header-link"
                >
                    <IconPromo />
                    Oferte
                </Link>
                <Link
                    id="categories-link"
                    to={ CATEGORIES_PAGE_URL }
                    className="header-link"
                >
                    <IconProducts />
                    Produse
                </Link>
                <CartThumbnail />
            </div>
        </header>
    );
};

Header.propTypes = {
    location: object.isRequired,
    history: object.isRequired,
    className: string,
};

export default withRouter(Header);
