import { merge, isPlainObject } from 'lodash-es';

// api interface for caching data
const API = {
    cache: new Map(),
    requests: new Map()
};

const defaultFetchOptions = {
    method: 'GET',
    headers: {
        Accept: 'application/json, text/plain',
        'Content-Type': 'application/json',
        'Cache-Control': 'public, no-cache, max-age=31536000',
    },
    mode: 'cors',
    credentials: 'same-origin',
    referrer: 'no-referrer',
    cache: 'default',
};

/**
 * Formats response based on content-type
 * @param {Response} response
 * @returns {Promise<Object>}
 */
const parseResponse = async response => {
    const contentTypeHeader = response.headers.get('content-type');
    const responseBody = contentTypeHeader.includes('pdf')
        ? await response.blob()
        : await response.text();
    let data = responseBody;
    try {
        data = JSON.parse(responseBody || null);
    } catch (err) {
        console.error(err);
    }

    if (response.status === 200) {
        const etag = response.headers.get('etag');
        if (etag) {
            const urlApiPath = response.url.replace(/.+?(?=\/api)/, '').replace(/\?.+/, '');
            API.cache.set(urlApiPath, { etag, data });
        }
    }
    return Object.assign(response, { data });
};

/**
 * Throws if status is anything else except [200, 300)
 * @param {Response} response
 * @returns {Response|ErrorEvent}
 */
const checkStatus = response => {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }
    if (response.status === 304) {
        const etag = response.headers.get('etag');
        if (etag) {
            console.info( // eslint-disable-line
                `%c__REQUEST__ Overriding 304 for: %c${ response.url }`,
                'color: blue',
                'font-weight: bold',
            );
            const urlApiPath = response.url.replace(/.+?(?=\/api)/, '').replace(/\?.+/, '');
            const cachedData = API.cache.get(urlApiPath);
            if (cachedData && cachedData.etag === etag) {
                return {
                    ...response,
                    data: cachedData.data
                };
            }
        }
        // no etag ?!
        return response;
    }
    if (response.status >= 300 && response.status < 500) {
        window.dispatchEvent(new CustomEvent('redirect', {
            detail: {
                pathname: '/login',
                state: response.data,
            },
        }));
    }

    throw response;
};

/**
 * Catches errors and throws them to the client
 * @param {Response} response
 */
const handleErrors = async response => {
    if (response.code === 20) {
        // Dupe request has been canceled, don't throw;
        return;
    }

    throw response.data;
};

export const getFetchCtrl = URI => {
    let urlInstance = URI;
    if (!(URI instanceof URL)) {
        urlInstance = new URL(`${ window.location.origin }${ URI }`);
    }
    return API.requests.get(urlInstance.pathname);
};

const fetchHelper = (path = null, options = {}) => {
    if (isPlainObject(path) && !options) {
        Object.assign(options, path);
    }
    const URI = (typeof path !== 'string' && path !== null) ? path.url : path;

    let urlInstance = URI;
    if (!(URI instanceof URL)) {
        urlInstance = new URL(`${ window.location.origin }${ URI }`);
    }

    const cache = API.cache.get(urlInstance.pathname);
    if (cache && cache.etag) {
        // Mutate options headers to assign etag
        Object.assign(options, {
            headers: {
                ...options.headers,
                'If-None-Match': cache.etag,
            },
        });
    }

    if (options.params) {
        if (isPlainObject(options.params)) {
            Object.entries(options.params).forEach(([key, value]) => {
                urlInstance
                    .searchParams
                    .append(encodeURIComponent(key), encodeURIComponent(value));
            });
        } else {
            /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
            console.error(`
                Params must be of type object.
                Instead received: ${ typeof options.params }
            `);
        }
    }

    const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');

    // Add auth headers
    if (
        currentUser.user_token
        && !urlInstance.pathname.includes('login')
    ) {
        Object.assign(options, merge(options, {
            headers: {
                Authorization: `Basic ${ currentUser.user_token }`,
            },
        }));
    }

    if (process.env.NODE_ENV === 'development') {
        urlInstance
            .searchParams
            .append('XDEBUG_SESSION_START', 'PHPSTORM');
    }

    const opts = merge({}, defaultFetchOptions, options);

    if (opts.method === 'GET' && !opts.noCancel) {
        // Cancel dupe not finished GET requests
        const controller = new AbortController();
        const prevReq = API.requests.get(urlInstance.pathname);

        if (prevReq) {
            prevReq.abort();
            console.info( // eslint-disable-line
                `%c__REQUEST__ Cancelled for URI: %c${ URI }`,
                'color: blue',
                'font-weight: bold',
            );
        }
        API.requests.set(urlInstance.pathname, controller);
        opts.signal = controller.signal;
    }

    return fetch(urlInstance, opts)
        .then(parseResponse)
        .then(checkStatus)
        .catch(handleErrors)
        .finally(() => {
            if (API.requests.has(urlInstance.pathname)) {
                API.requests.delete(urlInstance.pathname);
            }
        });
};

export default fetchHelper;
