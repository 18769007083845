import './Modals.scss';
import './InactivityModal.scss';

import React, { createRef, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { object } from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import {
    SETTINGS_PAGE_URL,
    HOME_PAGE_URL,
    AUTH_PAGE_URL,
    DASHBOARD_PAGE_URL,
    USER_PAGE_URL,
} from 'Constants';
import CartContext from 'Contexts/CartContext';

const INACTIVE_TIME_LIMIT = 1000 * 60 * 2; // in milliseconds
const COUNTDOWN_INTERVAL_LIMIT = 60 * 2; // in seconds
const avoidedPaths = [
    HOME_PAGE_URL,
    AUTH_PAGE_URL,
    DASHBOARD_PAGE_URL,
    USER_PAGE_URL,
    SETTINGS_PAGE_URL,
];
const events = ['touchstart', 'touchmove', 'touchend', 'click'];

export default
@withRouter
class InactivityModal extends PureComponent {
    static contextType = CartContext;

    static propTypes = {
        history: object,
        location: object,
    };

    /**
     * Timer for detecting user inactivity.
     * @type {number|null}
     */
    inactivityTimer = null;

    /**
     * Timer for the cart reset countdown.
     * @type {number|null}
     */
    countdownTimer = null;

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            countdown: this.getCountdownValue(COUNTDOWN_INTERVAL_LIMIT),
        };
        this.containerRef = createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.open !== prevState.open) {
            if (this.state.open) {
                document.body.style.overflowY = 'hidden';
            } else {
                document.body.style.overflowY = 'auto';
            }
        }
    }

    componentDidMount() {
        this.startInactivityTimer();
        events.forEach(event => window
            .addEventListener(event, this.onUserActivity));
    }

    componentWillUnmount() {
        document.body.style.overflowY = 'auto';
        events.forEach(event => window
            .removeEventListener(event, this.onUserActivity));
    }

    onUserActivity = e => {
        if (!e.target.closest('.inactive-modal')) {
            this.restartInactivityTimer();
        }
    };

    /**
     * Restart the timer for inactivity detection.
     */
    restartInactivityTimer = () => {
        this.setState({
            countdown: this.getCountdownValue(COUNTDOWN_INTERVAL_LIMIT),
        });
        this.toggleModal(false);
        this.startInactivityTimer();
    };

    shouldStartCountdown = () => {
        const { location: { pathname } } = this.props;

        return (
            (pathname === HOME_PAGE_URL && this.context.size() > 0)
            || !avoidedPaths.some(path => path.match(pathname))
        );
    };

    /**
     * Start inactivity detection timer.
     */
    startInactivityTimer = () => {
        clearTimeout(this.inactivityTimer);
        clearInterval(this.countdownTimer);

        this.inactivityTimer = setTimeout(() => {
            if (this.shouldStartCountdown()) {
                this.startCountDown(COUNTDOWN_INTERVAL_LIMIT);
                this.toggleModal(true);
            }
        }, INACTIVE_TIME_LIMIT);
    };

    /**
     * Reset the user session (=> empty the cart, clear qp and return to home).
     */
    resetSession = (navigate = false) => {
        this.toggleModal(false);
        this.context.emptyCart();
        this.startInactivityTimer();
        if (navigate) {
            this.props.history.replace(HOME_PAGE_URL);
            document.body.style.overflowY = 'auto';
        }
    };

    /**
     * Start the cart reset modal counter.
     * @param {int} duration
     */
    startCountDown = duration => {
        clearTimeout(this.inactivityTimer);
        clearInterval(this.countdownTimer);
        let timer = duration;

        this.countdownTimer = setInterval(() => {
            this.setState({
                countdown: this.getCountdownValue(timer),
            }, () => {
                if (--timer < 0) {
                    timer = duration;
                    this.resetSession(true);
                }
            });
        }, 1000);
    };

    /**
     * Converts timer limit to hmr format
     * @param timer {number}
     * @returns {string}
     */
    getCountdownValue = timer => {
        let minutes = parseInt(timer / 60, 10);
        let seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? `0${minutes}` : minutes;
        seconds = seconds < 10 ? `0${seconds}` : seconds;

        return `${minutes}:${seconds} ${minutes === '00' ? ' secunde' : ' minute'}`;
    };

    toggleModal = open => {
        if (this.state.open !== open) {
            this.setState({ open });
        }
    };

    render = () => (
        <div className="InactiveModal-component" ref={ this.containerRef }>
            <Modal
                onEnter={ () => {
                    document.body.style.overflowY = 'hidden';
                } }
                onExit={ () => {
                    document.body.style.overflowY = 'auto';
                } }
                centered
                container={ () => this.containerRef.current }
                show={ this.state.open }
                dialogClassName="container modal inactive-modal"
                size="sm"
                onHide={ () => {} }
            >
                <Modal.Header>
                    <Button
                        variant="secondary"
                        onClick={ this.restartInactivityTimer }
                    >
                        Inchide <span>x</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <p>Cosul se va reseta in { this.state.countdown }.</p>
                    <p>Pentru a continua cumparaturile, inchide aceasta fereastra.</p>
                </Modal.Body>
                <Modal.Footer>
                    { this.context.size() > 0 && (
                        <Button
                            variant="third"
                            onClick={ this.resetSession }
                        >
                            Goleste cosul
                        </Button>
                    ) }
                    <Button
                        variant="secondary"
                        onClick={ this.restartInactivityTimer }
                    >
                        Continua cumparaturile
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
