import './ErrorMessage.scss';

import React from 'react';
import { string } from 'prop-types';
import { htmlText } from 'Helpers';

const ErrorMessage = ({ message }) => {
    let messageToDisplay;
    if (typeof message !== 'string') {
        messageToDisplay = JSON.stringify(message);
    } else {
        messageToDisplay = message;
    }

    return (
        <div className="ErrorMessage">
        <span
            className="error"
            dangerouslySetInnerHTML={ htmlText(messageToDisplay) }
        />
        </div>
    );
};

ErrorMessage.propTypes = {
    message: string,
};

export default ErrorMessage;
