/**
 * The payment types used for order
 * @type {{
 *  now: {id: number, paymentMethod: string, paymentMethodTitle: string, setPaid: boolean},
 *  onDelivery: {id: number, paymentMethod: string, paymentMethodTitle: string, setPaid: boolean},
 *  installments: {id: number, paymentMethod: string, paymentMethodTitle: string, setPaid: boolean}
 * }}
 */

import promoImage from 'Assets/images/placeholder-image.png';

/* API routes */
export const API_ROOT = '/api/';
export const API_USER = `${ API_ROOT }user`;
export const API_AUTH = `${ API_USER }/login`;
export const API_LOGOUT = `${ API_USER }/logout`;
export const API_AUTH_VALIDATE = `${ API_USER }/validate`;
export const API_USER_VALIDATE_PIN = `${ API_USER }/validate-pin`;
export const API_FILTERS_URL = `${ API_ROOT }filters`;
export const API_PRODUCTS_URL = `${ API_ROOT }products`;
export const API_CATEGORIES_URL = `${ API_PRODUCTS_URL }/categories`;
export const API_PROMOS_URL = `${ API_ROOT }promos`;
export const API_SHIPPING_URL = `/wp-json/wc/v3/shipping/zones/1/methods`;
export const API_PAGES_URL = `/wp-json/wp/v2/pages`;
export const API_ORDERS_URL = `${ API_ROOT }orders`;

/* Front app routes */
export const HOME_PAGE_URL = '/';
export const AUTH_PAGE_URL = '/login';
export const USER_PAGE_URL = '/user';
export const SETTINGS_PAGE_URL = `${USER_PAGE_URL}/settings`;
export const DASHBOARD_PAGE_URL = `${USER_PAGE_URL}/dashboard`;
export const PROMOS_PAGE_URL = '/promos';
export const PRODUCTS_PAGE_URL = '/products/';
export const CATEGORIES_PAGE_URL = '/categories';
export const CART_PAGE_URL = '/cart';
export const ORDER_PAGE_URL = '/order/';
export const CLEAR_PAGE_URL = '/cl';

export const PAGE_URL_CLASSNAMES_MAP = {
    [AUTH_PAGE_URL]: 'AuthPage',
    [HOME_PAGE_URL]: 'HomePage',
    [PROMOS_PAGE_URL]: 'PromosPage',
    [CATEGORIES_PAGE_URL]: 'CategoriesPage',
    [PRODUCTS_PAGE_URL]: 'ProductsPage',
    [CART_PAGE_URL]: 'CartPage',
    [ORDER_PAGE_URL]: 'OrderPage',
    [DASHBOARD_PAGE_URL]: 'DashboardPage',
};

export const PROMOTION_TYPES = Object.freeze({
    PRGF: 'PRGF',
    BNDL: 'BNDL',
    DSC: 'DSC',
});

export const PROMOTION_TYPE_LABELS = Object.freeze({
    PRGF: 'Produs cu cadouri',
    BNDL: 'Oferta pachet',
    DSC: 'Produs redus',
});

export const PROMOTION_BUY_LABELS = Object.freeze({
    PRGF: 'Cumpara pachet cadou',
    BNDL: 'Cumpara-le impreuna',
    DSC: 'Cumpara cu reducere',
});

export const placeHolderImage = {
    src: promoImage,
    alt: 'default',
};
