import React from 'react';
import { node } from 'prop-types';

import CartProvider from './CartProvider';
import ProductsProvider from './ProductsProvider';
import CategoriesProvider from './CategoriesProvider';

const Providers = ({ children }) => (
    <CartProvider>
        <CategoriesProvider>
            <ProductsProvider>
                { children }
            </ProductsProvider>
        </CategoriesProvider>
    </CartProvider>
);

Providers.propTypes = {
    children: node,
};

export default Providers;
