import React, { PureComponent } from 'react';
import { node, object } from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { merge, assign } from 'lodash-es';

import UserContext from 'Contexts/UserContext';
import { API_LOGOUT, AUTH_PAGE_URL } from 'Constants';
import fetchHelper from 'Helpers/fetch';

export default
@withRouter
class UserProvider extends PureComponent {
    static propTypes = {
        children: node,
        location: object,
    };

    /* eslint-disable react/no-unused-state */
    constructor(props) {
        super(props);

        if (!localStorage.getItem('currentUser')) {
            localStorage.setItem('currentUser', '{}');
        }

        const currentUser = JSON.parse(localStorage.getItem('currentUser'));

        if (!localStorage.getItem('users')) {
            localStorage.setItem('users', '{}');
        }

        this.state = {
            users: JSON.parse(localStorage.getItem('users')),
            currentUser,
            loginUser: this.loginUser,
            logoutUser: this.logoutUser,
            updateUser: this.updateUser,
            deleteUser: this.deleteUser,
        };
    }

    get value() {
        return this.state;
    }

    /**
     * Add user to users dataset
     * @param {object} user
     */
    loginUser = user => {
        this.setState(prevState => {
            const oldUser = prevState.users[user.user_email] || {};
            const users = {
                ...prevState.users,
                [user.user_email]: merge(oldUser, user, {
                    token: user.user_token,
                    loggedIn: true,
                }),
            };
            return {
                ...prevState,
                users,
                currentUser: users[user.user_email],
            };
        }, () => {
            const { users, currentUser } = this.state;
            localStorage.setItem('users', JSON.stringify(users));
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
        });
    };

    /**
     * Updates current user
     * @param {object} data
     */
    updateUser = data => new Promise(resolve => {
        this.setState(prevState => {
            const newUser = assign(prevState.currentUser, { ...data });
            const newUsers = prevState.users;
            if (newUser.user_email) {
                Object.assign(newUsers, {
                    [newUser.user_email]: newUser,
                });
            }
            return {
                ...prevState,
                users: newUsers,
                currentUser: newUser,
            };
        }, () => {
            const { users, currentUser } = this.state;
            localStorage.setItem('users', JSON.stringify(users));
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
            return resolve();
        });
    });

    /**
     * Deletes a user
     * @param { string } user - USER EMAIL ONLY !
     */
    deleteUser = user => {
        const { users, currentUser } = this.state;
        delete users[user];
        const payload = {
            users,
        };
        if (user === currentUser.user_email) {
            payload.currentUser = {};
        }
        this.setState(payload);
    }

    /**
     * Logs out user
     * @returns {Promise<*>}
     */
    logoutUser = async () => fetchHelper(API_LOGOUT, {
        method: 'POST',
        body: JSON.stringify({ userId: this.state.currentUser.user_id }),
    })
        .then(() => {
            this.setState(prevState => ({
                ...prevState,
                users: Object.assign(prevState.users, {
                    [prevState.currentUser.user_email]: {
                        ...prevState.currentUser,
                        loggedIn: false,
                    },
                }),
                currentUser: {},
            }), () => {
                const { users, currentUser } = this.state;
                localStorage.setItem('users', JSON.stringify(users));
                localStorage.setItem('currentUser', JSON.stringify(currentUser));
                return Promise.resolve();
            });
        });
    /* eslint-enable react/no-unused-state */

    render() {
        const { location } = this.props;
        const { currentUser } = this.state;
        if (
            !currentUser.user_email
            && !currentUser.token
            && location.pathname !== AUTH_PAGE_URL
        ) {
            return (
                <Redirect
                    push={ false }
                    to={ {
                        pathname: AUTH_PAGE_URL,
                        state: {
                            ...location,
                            message: `Inainte de a folosi aplicatia trebuie sa te loghezi.`,
                        },
                    } }
                />
            );
        }
        return (
            <UserContext.Provider value={ this.value }>
                { this.props.children }
            </UserContext.Provider>
        );
    }
}
