// TODO: lots of bloat here, try to get rid of it !
import 'bootstrap/scss/bootstrap.scss';
import 'Assets/styles/App.scss';

import React, { PureComponent, Suspense, lazy } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { object } from 'prop-types';
import clsx from 'clsx';

import {
    HOME_PAGE_URL,
    CATEGORIES_PAGE_URL,
    PRODUCTS_PAGE_URL,
    CART_PAGE_URL,
    ORDER_PAGE_URL,
    PROMOS_PAGE_URL,
    AUTH_PAGE_URL,
    USER_PAGE_URL,
    PAGE_URL_CLASSNAMES_MAP,
    CLEAR_PAGE_URL
} from 'Constants';

import Loader from 'Components/Loader/Loader';
import Header from 'Components/Header/Header';
import ErrorBoundary from 'Containers/ErrorBoundary/ErrorBoundary';
import Providers from 'Providers';
import WizardModal from 'Components/Modals/WizardModal';
import InactivityModal from 'Components/Modals/InactivityModal';
import UserContext from 'Contexts/UserContext';
import withSession from 'Containers/Hoc/withSession';

// Pages
const Homepage = lazy(() => import('Pages/Home/Homepage'));
const Categories = lazy(() => import('Pages/Categories/Categories'));
const Products = lazy(() => import('Pages/Products/Products'));
const Promos = lazy(() => import('Pages/Promos/Promos'));
const Product = lazy(() => import('Pages/Product/Product'));
const Cart = lazy(() => import('Pages/Cart/Cart'));
const Order = lazy(() => import('Pages/Order/Order'));
const NotFound = lazy(() => import('Components/NotFound/NotFound'));
const ClearStorage = lazy(() => import('Pages/ClearStorage'));
const Auth = lazy(() => import('Pages/Auth/AuthPage'));
const User = lazy(() => import('Pages/User/UserPage'));

const getPageClassName = page => {
    if (page in PAGE_URL_CLASSNAMES_MAP) {
        return PAGE_URL_CLASSNAMES_MAP[page];
    }
    if (page.includes('/order/success') || page.includes('/order/failure')) {
        return PAGE_URL_CLASSNAMES_MAP[ORDER_PAGE_URL];
    }
    if (page.includes('/products/')) {
        return PAGE_URL_CLASSNAMES_MAP[PRODUCTS_PAGE_URL];
    }
    return '';
};

class App extends PureComponent {
    static contextType = UserContext;

    static propTypes = {
        location: object.isRequired,
        history: object.isRequired
    };

    componentDidMount() {
        window.addEventListener('redirect', this.onRedirect);
    }

    componentWillUnmount() {
        window.removeEventListener('redirect', this.onRedirect);
    }

    onRedirect = e => {
        const { history } = this.props;
        history.push(e.detail.pathname, e.detail.state);
    };

    render() {
        const { location } = this.props;
        return (
            <div className={ clsx('App', getPageClassName(location.pathname)) }>
                <ErrorBoundary>
                    <Providers>
                        <Header />
                        <Suspense fallback={ <Loader /> }>
                            <Switch>
                                <Route
                                    path={ AUTH_PAGE_URL }
                                    exact
                                    component={ Auth }
                                />
                                <Route
                                    path={ USER_PAGE_URL }
                                    render={ props => <User { ...props } /> }
                                />
                                <Route
                                    path={ HOME_PAGE_URL }
                                    exact
                                    render={ props => <Homepage { ...props } /> }
                                />
                                <Route
                                    path={ CATEGORIES_PAGE_URL }
                                    exact
                                    render={ props => <Categories { ...props } /> }
                                />
                                <Route
                                    path={ PROMOS_PAGE_URL }
                                    exact
                                    component={ Promos }
                                />
                                <Route
                                    path={ PRODUCTS_PAGE_URL }
                                    exact
                                    component={ Products }
                                />
                                <Route
                                    path={ `${ PRODUCTS_PAGE_URL }:productId/:promoId?` }
                                    exact
                                    component={ Product }
                                />
                                <Route
                                    path={ CART_PAGE_URL }
                                    exact
                                    component={ Cart }
                                />
                                <Route
                                    path={ ORDER_PAGE_URL }
                                    render={ props => (
                                        <Order
                                            currentUser={ this.context.currentUser }
                                            userCtx={ this.context }
                                            { ...props }
                                        />
                                    ) }
                                />
                                <Route
                                    path={ CLEAR_PAGE_URL }
                                    exact
                                    component={ ClearStorage }
                                />
                                <Route component={ NotFound } />
                            </Switch>
                        </Suspense>
                        <WizardModal />
                        <InactivityModal />
                    </Providers>
                </ErrorBoundary>
            </div>
        );
    }
}

export default withSession(withRouter(App));
